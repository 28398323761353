import React, { useState, useEffect, FunctionComponent, } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	Box, Container, Grid, Input, Typography, CssBaseline, Card, CardContent, CardHeader, IconButton, Select, MenuItem, Button, TextField, Checkbox, ListItemText, InputLabel
} from '@material-ui/core';
import { useSnackbar } from "notistack"
import { RouteComponentProps, Redirect } from "react-router"
// import { isValidPhoneNumber } from 'libphonenumber-js';

import MiniDrawer from "../../../common/components/SideDrawer"
import useStyles from './styles';
import { CustomUser, } from '../../../common/contracts/dashboard';
import { RootState } from '../../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { MASTER_PERMISSIONS, USER_ROLES, USER_TYPES, USER_TYPE_VALUES } from '../../../../utilities/constants';
import { deleteUser, getRolesSecand, getUser, hardDeleteUser, updateUserDetails, updateUserPreferences } from '../../../../api/dashboard';
import { ArrowBack } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { roles } from '../../../edumaticaAcademy/enums';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ConfirmationModal from '../../../common/components/confirmation_modal';



interface Props extends RouteComponentProps {
	user: CustomUser
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const EditCusRole: FunctionComponent<Props> = ({ user, history, location }) => {

	const { enqueueSnackbar } = useSnackbar();
	const state: any = location?.state;

	console.log("state-->", state, "user-->", user)
	const [redirectTo, setRedirectTo] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false);
	//@ts-ignore
	const [userDetails, setUserDetails] = useState<any>(location?.state?.data); //crosscheck
	const [meetingPreference, setMeetingPreference] = useState('')
	const [isContentDownloadable, setIsContentDownloadable] = useState('')
	const [isRecordingDownloadable, setIsRecordingDownloadable] = useState('')
	const [autoRecord, setautoRecord] = useState('')
	const [contentDownloadRole, setContentDownloadRole] = useState<roles[]>([])
	const [recordingDownloadRole, setRecordingDownloadRole] = useState<roles[]>([])
	const [wapBox, setWapBox] = useState<string>('')
	const [wapChannelId, setWapChannelId] = useState<string>('')
	const [wapApiKey, setWapApiKey] = useState<string>('')
	const [wapApiSecret, setWapApiSecret] = useState<string>('')
	const [instituteType, setInstituteType] = useState<string>('')
	const [onJobTraningType, setOnJobTraning] = useState<boolean>(false)

	const [wapAccountSid, setWapAccountSid] = useState<string>('')
	const [wapAuthToken, setWapAuthToken] = useState<string>('')
	const [wapFrom, setWapFrom] = useState<string>('')
	//@ts-ignore
	const [emailId, setEmailId] = useState<string>('-')
	const [emailIdArr, setEmailIdArr] = useState<string[]>([])
	const [customContactNumber, setCustomContactNumber] = useState<string>('')
	const [customContactNumberArr, setCustomContactNumberArr] = useState<string[]>([])

	//@ts-ignore
	const [ownerArr, setOwnerArr] = useState<any[]>([])
	const [attendanceType, setAttendanceType] = useState('')
	const [isWeekendView, setIsWeekendView] = useState<boolean>(false)
	const [dayList, setDayList] = useState<any[]>([{ day: 'Monday', value: 'Monday' }, { day: 'Tuesday', value: 'Tuesday' }, { day: 'Wednesday', value: 'Wednesday' }, { day: 'Thursday', value: 'Thursday' }, { day: 'Friday', value: 'Friday' }, { day: 'Saturday', value: 'Saturday' }, { day: 'Sunday', value: 'Sunday' }])
	const [weekEndList, setWeekEndList] = useState<any[]>([{ dayName: '', fullDay: true }])
	const [roleList, setRoleList] = useState<any>([]);
	const [roleListMaped, setRoleListMaped] = useState<any>([]);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
	const [softDelete, setSoftDelete] = useState(true)

	const [newMobileNo, setNewMobileNo] = useState(userDetails.mobile ? userDetails.mobile : '');
	const [newEmail, setNewEmail] = useState(userDetails.email ? userDetails.email : '');

	const [entityId, setEntityId] = useState('');
	const [userRoleName, setUserRoleName] = useState('');
	const [checkboxChecked, setCheckboxChecked] = useState<any>();
	const [selectedOwnerRole, setSelectedOwnerRole] = useState<any>();






	const styles = useStyles();
	const dispatch = useDispatch();

	console.log("statestate", state)
	useEffect(() => {
		getRoles();
	}, []);

	console.log("ownerArr -->", ownerArr)


	const getRoles = async()=>{
 
		try {
			//@ts-ignore
		setOwnerArr(location?.state?.data?.owner)	
		  let data = await getRolesSecand()
		console.log("datagetRolesSecand",data); 
		let mapeedArray = data.map((elem:any)=>elem?.name)
		setRoleListMaped([...mapeedArray])
		setRoleList([...data])
		} catch (error) {
		  console.log("error",error);
		  
		}
		
	  }
	
	


	if (redirectTo.length > 0) {
		return <Redirect to={redirectTo} />;
	}

	const handleCheckBox = (event: any, itr: any, smallIndex: number) => {
		console.log("event.target--", event.target.checked)
		console.log("itr--", itr, "smallIndex-->", smallIndex)
		if (event.target.checked) {
			setCheckboxChecked(itr); // Set the selected checkbox
		} else {
			setCheckboxChecked(null); // Deselect if unchecked
		}
		setSelectedOwnerRole(itr)
		// setCheckboxChecked(event.target.checked);
	}


	const handleNewMobileNoChange = (event: any) => {
		setNewMobileNo(event.target.value);
	};

	const handleNewEmailId = (event: any) => {
		setNewEmail(event.target.value);
	};


	console.log("selectedOwnerRole -->", selectedOwnerRole)




	let userType: any;

	const saveDetails = async () => {
		let roleEntityId = selectedOwnerRole?.entityId; // Access entityId
		let roleName = selectedOwnerRole?.role?.name; // Access role name
		let roleId = selectedOwnerRole?.role?._id; // Access role name


		if (roleName == 'ROLE_ORGANIZATION') {
			userType = "org";
		}
		else if (roleName == 'ROLE_ORGANIZATION_TUTOR') {
			userType = "tutor";
		} else if (roleName == 'ROLE_STUDENT') {
			userType = "student";
		} else if (roleName == 'ROLE_PARENT') {
			userType = "parent";
		}

		console.log("inside roleEntityId ", roleEntityId)
		console.log("inside roleName ", roleName)
		console.log("newEmail-->", newEmail, "newMobileNo-->", newMobileNo, "userType-->", userType, "entityId--->", entityId, "roleId-->", roleId)

		setLoading(true);
		try {
			let obj = { ...userDetails }
			obj.newMobileNo = newMobileNo; // Add newMobile
			obj.newEmailId = newEmail; // Add newEmail
			obj.userType = userType; // Add userType
			obj.entityId = roleEntityId; // Add entityId
			obj.roleId = roleId; // Add entityId


			obj.owner = ownerArr
			let message = await updateUserDetails(obj)
					enqueueSnackbar('Updated successfully', { variant: 'success' });
					setLoading(false);
			history.goBack()
			
		} catch (error) {
			console.log("error",error);
			setLoading(false);
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
		}
	}
	const deleteUserApi = async () => {
		setLoading(true);
		setOpenConfirmationModal(false)
		try {
			
			let ID = userDetails?._id
			console.log("ID",ID);
			if(softDelete)
			 await deleteUser(ID)
			else
			await hardDeleteUser(ID)
					enqueueSnackbar('User Deleted successfully', { variant: 'success' });
					setLoading(false);
			history.goBack()
			setSoftDelete(true)
			
		} catch (error) {
			console.log("error",error);
			setLoading(false);
			enqueueSnackbar('Something went wrong', { variant: 'warning' });
			setSoftDelete(true)
		}
	}


	const handlerChangeRole = (value: string, index: number,smallIndex :number) => {
		console.log("value",value,"index",index,"smallIndex",smallIndex)
		let filterRole = roleList.filter((elem:any)=>elem?.name === value)
		console.log("filterRole",filterRole);
		
		let obj = {actionCount:1,
			name:"ROLE_ORGANIZATION",
			status:1,
			_id:"5f7fe12beadfff36a4abb952"}
		console.log("ownerArr",ownerArr)
		
		const allGrp = [...ownerArr]
		let arr = [...allGrp[index]?.roles]
		console.log("arr[smallIndex]",arr[smallIndex])

		arr[smallIndex] = {
			entityId:arr[smallIndex]?.entityId,
			role:{actionCount:1,
			name:filterRole[0]?.name,
			status:1,
			_id:filterRole[0]?._id},
			_id:arr[smallIndex]?._id
		}
				console.log("arr[smallIndex] AFTER",arr[smallIndex])

		allGrp[index].roles = [...arr]
		setOwnerArr([...allGrp])

	}
	const handlerDeleteWeekend = (index: number,smallIndex :number) => {
		const allGrp = [...ownerArr]
		let arr = [...allGrp[index]?.roles]
		// const allGrp = [...weekEndList]
		arr.splice(smallIndex, 1)
		allGrp[index].roles = [...arr]
		setOwnerArr([...allGrp])


	}
	const handlerAddRole = (index:number) =>{
		const allGrp = [...ownerArr]
		let arr = [...allGrp[index]?.roles]
		arr.push({})
		allGrp[index].roles = arr.reverse()
		setOwnerArr([...allGrp])

	}
	const handlerDeleteOwner =(index:number) => {
		const allGrp = [...ownerArr]
		
		allGrp.splice(index, 1)
		
		setOwnerArr([...allGrp])
	}


	const getRoleSecand = (userType:any) => {
		if (userType === "ROLE_TUTOR") return "TUTOR";
		if (userType === "ROLE_STUDENT") return "STUDENT";
		if (userType === "ROLE_ADMIN") return "ADMIN";
		if (userType === "ROLE_ORGANIZATION") return "ORGANIZATION";
		if (userType === "ROLE_PARENT") return "PARENT";
		if (userType === "ROLE_ORGANIZATION_TUTOR") return "ORGANIZATION TUTOR";
		if (userType === "ROLE_ORG_ADMIN") return "ORGANIZATION ADMIN";
		return null;
	};




	return (
		<>
			<CssBaseline />
			<MiniDrawer>
				<div style={{ height: '80vh', width: '100%' }}>
					<Container style={{ width: '100%' }}>
						<Grid container >
							{/* <Grid item className={styles.header}>
								<Typography variant="h5" >Customer Management</Typography>
							</Grid> */}
							<Grid item className={styles.header}>
								<IconButton onClick={() => history.goBack()}>
									<ArrowBack />
								</IconButton>
								<Typography variant="h5" >{localStorage.getItem('welcomeMsg') || ''}</Typography>
							</Grid>
							<Card className={styles.card} variant='outlined'>
								<CardHeader title='Edit Customer' titleTypographyProps={{ varaint: 'h5' }} />
								<CardContent>
									{/* for oldEmail */}
									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Email</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={userDetails.email ? userDetails.email : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									{/* for newEmail */}
									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>New Email</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={newEmail}
													onChange={handleNewEmailId}
												/>
											</FormControl>
										</Grid>
									</Grid>

									{/* for oldMobile */}
									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>Mobile</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={userDetails.mobile ? userDetails.mobile : ''}
													disabled
												/>
											</FormControl>
										</Grid>
									</Grid>

									{/* for newMobile */}
									<Grid container className={styles.paddClass}>
										<Grid item xs={12} md={4}>
											<FormControl fullWidth margin="normal">
												<Box className={styles.label}>New Mobile</Box>
											</FormControl>
										</Grid>

										<Grid item xs={12} md={8}>
											<FormControl fullWidth margin="normal">
												<Input
													value={newMobileNo}
													onChange={handleNewMobileNoChange}
												/>
											</FormControl>
										</Grid>
									</Grid>

										<Grid container className={styles.paddClass}>
											<Grid item xs={12} md={4}>
												<FormControl fullWidth margin="normal">
													<Box className={styles.label}>ROLES</Box>
												</FormControl>
											</Grid>

											<Grid item xs={12} md={8}>
												<Grid container md={12} sm={12} xs={12} >
													{ownerArr && ownerArr.map((elem: any, index: any) => <Grid container md={12} sm={12} xs={12} >
													<Grid item md={10} sm={10} xs={10} style={{ marginTop: '3%' }} >
													<FormControl fullWidth margin="normal">
													<Box className={styles.label}>{index+1}) Owner Id : {elem?.ownerId}</Box>

												</FormControl></Grid>
														<Grid item md={1} sm={1} xs={1} style={{ marginTop: '4.5%' }} >
																	{/* <AddCircleIcon color="secondary" onClick={() => { handlerAddRole(index as number) }} style={{ color: 'blue', marginLeft: '30%', float: 'left' }} /> */}
																	<DeleteForeverIcon color="secondary" onClick={() => { handlerDeleteOwner(index as number) }} style={{ color: 'blue', marginRight: '-19%', float: 'right' }} />

																</Grid>
																{/* <Grid item md={1} sm={1} xs={1} style={{ marginTop: '4%' }} >

																</Grid> */}
														<Grid container md={12} sm={12} xs={12} justify="space-between" spacing={2}  >
															{elem?.roles?.length > 0 && elem?.roles.map((itr: any, smallIndex: number) => <>
																<Grid item md={1} sm={1} xs={1} style={{ marginTop: '3%' }} >
																	<Checkbox
																		checked={checkboxChecked}
																		onChange={(e) => handleCheckBox(e, itr, smallIndex)}
																		color="primary"
																		inputProps={{ 'aria-label': 'Select Role Checkbox' }}
																	/>


																</Grid>
																<Grid item md={10} sm={10} xs={10}  >

																	<FormControl
																		style={{ width: '104%', backgroundColor: '#FFFFFF' }}
																		margin="normal"
																		variant="outlined"
																	>

																		<InputLabel id="demo-simple-select-label">
																			Select Role
																		</InputLabel>

																		<Select
																			label={'Select Role'}
																			value={itr?.role?.name}
																			onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
																				const word = e.target.value
																				handlerChangeRole(word as string, index as number ,smallIndex as number);
																			}}
																			disabled={true}
																			
																		>
																			<MenuItem value="" disabled>
																				Select Day
																			</MenuItem>
																			{roleList?.map((elem: any) => {
																				return <MenuItem value={elem?.name}>
																					{getRoleSecand(elem?.name)}
																				</MenuItem>
																			})}


																		</Select>

																	</FormControl>

																</Grid>
																 <Grid item md={1} sm={1} xs={1} style={{ marginTop: '3%' }} >
																	<DeleteForeverIcon color="secondary" onClick={() => { handlerDeleteWeekend(index as number , smallIndex  as number) }} style={{ color: 'red', marginRight: '-19%', float: 'right' }} />

																</Grid>
															</>)}


														</Grid>
														
													</Grid>
													)}

												</Grid>
											</Grid>
										</Grid>


										<Grid container xs={12} justify='flex-end'>
											<span style={{ float: 'right', paddingRight: '5%', paddingTop: '25px' }} >
												<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={saveDetails}>
													Save
												</Button>
											</span>
										</Grid>
										{userDetails?.status==1?<Grid container xs={12} justify='flex-end'>
											<span style={{ float: 'right', paddingRight: '5%', paddingTop: '25px' }} >
												<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={()=>{setOpenConfirmationModal(true);setSoftDelete(true)}}>
													Delete User
												</Button>
											</span>
										</Grid>:
										<Grid container xs={12} justify='flex-end'>
										<span style={{ float: 'right', paddingRight: '5%', paddingTop: '25px' }} >
											<Button style={{ marginLeft: '15px' }} color="primary" disableElevation variant="contained" onClick={()=>{setOpenConfirmationModal(true);setSoftDelete(false)}}>
												Delete User Permenently
											</Button>
										</span>
										</Grid>
										}
									

								</CardContent>
							</Card>
							{openConfirmationModal && <ConfirmationModal
					header="Delete User"
					helperText="Are you sure you want to delete?"
					openModal={openConfirmationModal}
					onClose={()=>setOpenConfirmationModal(false)}
					handleDelete={()=>deleteUserApi()}
				/>}
						</Grid>
					</Container>
				</div >
			</MiniDrawer >
		</>
	);
}

const mapStateToProps = (state: RootState) => ({
	user: state.adminReducer.user as CustomUser,
});

export default connect(mapStateToProps)(EditCusRole);